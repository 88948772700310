import { createApp } from 'vue'
import App from './App.vue'

import SmoothReflow from './components/utils/SmoothReflow'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import {faClone, faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faDiscord, faGithub, faTelegram} from "@fortawesome/free-brands-svg-icons";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(faEnvelope, faClone, faCheck, faTelegram, faGithub, faDiscord)


createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('SmoothReflow', SmoothReflow)
    .mount('#app')

