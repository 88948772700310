<template>
  <div class="item" :class="{ active: showInfo}">

    <div class="title-wrapper">
      <div class="title interactive" @click="toggleShow">
        <span>{{ title }}</span>
      </div>
      <div class="title-hit">{{ titleHit }}</div>
      <span class="description">{{ description }}</span>
    </div>

    <!--    <div class="content" @mouseenter="hover" @mouseleave="unhover">-->
    <SmoothReflow>
      <div class="content" v-if="showInfo">
        <slot></slot>
      </div>
    </SmoothReflow>

  </div>
</template>

<script>
export default {
  name: "PortfolioItem",

  props: {
    description: String,
    title: String
  },

  data() {
    return {
      showInfo: false,
      showTitle: true,
      canSizing: true,

      titleHit: ".show"
    }
  },

  methods: {
    toggleShow: function () {
      this.showInfo = !this.showInfo;
      this.titleHit = "";

      setTimeout(() => {
            this.titleHit = this.showInfo ? ".hide" : ".show"
          },
          500
      )
      ;
    }
  }

}
</script>

<style scoped>

.item {
  display: flex;
  padding: 1rem;
}

.title-wrapper {
  position: relative;
  width: 10rem;
}

.title-hit {
  position: absolute;
  right: 0;
  top: -2rem;

  display: none;

  color: var(--font-color-m20);
  font-size: 1.5rem;
}

.title {
  flex: 0 0 10rem;

  display: flex;
  height: 10rem;
  max-height: 10rem;
  width: 100%;

  justify-content: center;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;

  border: 3px solid var(--primal-color);

  transition: border-color var(--duration), border-width var(--duration-fast), box-shadow var(--duration);
}

.description {
  color: var(--font-color-m40);
  font-size: 1.2rem;
  line-height: 1.7rem;
}

.title:hover,
.item.active .title {
  font-weight: 500;
  border-width: 6px;
}

.title:hover ~ .title-hit {
  display: block;
}

.content {
  width: 30rem;
  padding-left: 1.5rem;

  font-size: 1.3rem;
}

.item.active .title {
  border-color: var(--second-color);
  box-shadow: 0 0 1rem var(--second-color);
}

@media (max-width: 650px) {
  .item {
    margin-top: 1rem;
  }

  .title-hit {
    display: block;
  }

  .content {
    width: 16rem;
  }

}

</style>