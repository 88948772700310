<template>
  <div class="profile">
    <div class="headers">
      <h1 class="bold">
        <div class="flashing-square"></div>
        Vasiliy Voronko
      </h1>
      <h2 class="sub-title">//
        <span class="block-underline"> Full-Stack Developer</span>
        <span class="sub-title-help help"> [portfolio];</span>
      </h2>
    </div>
    <div class="contacts">
      <div class="social">
        <h3>Contact</h3>
        <a href="https://t.me/ryt_13" title="@ryt_13">
          <img src="@/assets/icons/social/tg.svg" alt="t.me/ryt_13">
        </a>

        <a href="https://github.com/RyT-13" title="RyT-13">
          <img src="@/assets/icons/social/github.svg" alt="github.com/RyT-13">
        </a>

        <a href="https://discordapp.com/users/370303550317920257/" title="RyT#1735">
          <img src="@/assets/icons/social/discord.svg" alt="RyT#1735">
        </a>
      </div>

      <div class="mail">
        <a href="mailto: mail@voronko.dev">
          mail@voronko.dev
        </a>
        <span @click="copyToBuffer" @mouseleave="unhover" :class="{ copied: copied}" title="Copy">
              <img src="@/assets/icons/copy.svg" alt="Copy">
          </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheProfile",

  data() {
    return {
      copied: false
    }
  },

  methods: {
    copyToBuffer() {
      navigator.clipboard.writeText('mail@voronko.dev')
          .then(() => {
            this.copied = true;
          })
          .catch(err => {
            console.log('Something went wrong', err);
          });
    },

    unhover() {
      if (this.copied) {
        setTimeout(() => {
          this.copied = false;
        }, 500);
      }
    }
  }
}
</script>

<style scoped>
.profile {
  display: flex;
  max-width: 150rem;
  margin-right: auto;
  margin-left: auto;
  padding: 4rem calc(4rem + 6vw) 0;

  justify-content: space-between;
}

.headers {
  position: relative;
  align-self: flex-start;
}

.flashing-square {
  position: absolute;
  top: 2rem;
  left: -3rem;

  width: 1rem;
  height: 1rem;

  background-color: var(--primal-color);

  animation: flashing 2s infinite alternate;
}

.sub-title {
  margin-left: 25rem;
}

.contacts {
  align-self: flex-end;

  display: flex;
  padding-top: 1.5rem;

  flex-direction: column;
  align-items: flex-end;
}

.social {
  display: flex;
  margin-bottom: 10px;
}

.social img {
  margin-left: 1rem;
  transition: box-shadow var(--duration);
}

.social img:hover {
  filter: brightness(0) saturate(100%) invert(68%) sepia(41%) saturate(3303%) hue-rotate(220deg) brightness(105%) contrast(105%);
  box-shadow: 0 0 1rem var(--second-color);
}

.social h3 {
  margin-right: 2rem;
}

.mail {
  white-space: nowrap;

  font-size: 2.5rem;
  font-weight: 300;
  line-height: 4rem;
}

.mail a {
  color: var(--primal-color);
}

.mail img {
  margin-left: 0.5rem;
  cursor: pointer;
}

.mail img:hover {
  filter: brightness(0) saturate(100%) invert(68%) sepia(41%) saturate(3303%) hue-rotate(220deg) brightness(105%) contrast(105%);
}

.mail .copied img {
  opacity: 0.4;
  cursor: initial;
}

@keyframes flashing {
  from {
    box-shadow: none;
    background-color: var(--primal-color);
  }

  to {
    background-color: var(--second-color);
    box-shadow: 0 0 1rem var(--second-color);
  }
}

@media (max-width: 1560px) {
  .sub-title {
    margin-left: 9rem;
  }
}

@media (max-width: 1235px) {
  .sub-title {
    margin-left: 0;
  }
}

@media (max-width: 1100px) {
  .profile {
    margin-right: auto;
    margin-left: auto;
    padding: 4rem 0 0;

    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  .headers {
    margin-left: calc(2rem + 6vw);
  }

  .sub-title-help {
    display: none;
  }

  .contacts {
    margin-top: 3rem;
    margin-right: calc(2rem + 6vw);
  }
}

@media (max-width: 500px) {
  .headers {
    align-self: center;
    margin-left: 0;
  }

  .contacts {
    display: none;
    align-self: center;
    margin: 3rem 0 0;
  }
}

</style>