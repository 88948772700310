<template>
  <div class="bg">
    <div class="grid"></div>
  </div>
</template>

<script>
export default {
  name: "TheBackground"
}
</script>

<style scoped>

.bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;

  width: 100vw;
  height: 100vh;
}

.grid {
  width: 100%;
  height: 100%;

  background: url("~@/assets/bg-grid.svg");
}

.bg::before,
.bg::after {
  content: '';

  position: absolute;
  z-index: -1;

  height: calc(47rem + 20vmin);
  width: calc(47rem + 20vmin);

  border-radius: 50%;

  filter: blur(150px);
  -webkit-filter: blur(150px);
  -moz-filter: blur(150px);
  -o-filter: blur(150px);
  -ms-filter: blur(150px);
}

.bg::before {
  bottom: 0;
  left: 0;

  transform: translate(-50%, 50%);

  background: linear-gradient(141.68deg, #EB49D1 14.75%, #7CE0FF 87.01%);
}

.bg::after {
  top: 50%;
  right: 0;

  transform: translate(50%, -50%);

  background: linear-gradient(141.68deg, #EB4960 14.75%, #FF9C7C 87.01%);
}


@media (min-width: 2565px) {
  .bg::before,
  .bg::after {
    height: calc(47rem + 20vmin);
    width: calc(47rem + 20vmin);
  }

  .bg::before {
    transform: translate(-40%, 50%);
  }

  .bg::after {
    transform: translate(40%, -50%);
  }
}

@media (max-width: 1640px) {
  .bg::before,
  .bg::after {
    height: calc(47rem + 20vmin);
    width: calc(47rem + 20vmin);
  }

  .bg::before {
    transform: translate(-55%, 50%);
  }

  .bg::after {
    transform: translate(55%, -50%);
  }
}

@media (max-width: 1560px) {
  .bg::before,
  .bg::after {
    height: calc(47rem + 20vmin);
    width: calc(47rem + 20vmin);
  }

  .bg::before {
    transform: translate(-60%, 50%);
  }

  .bg::after {
    transform: translate(60%, -50%);
  }
}

@media (max-width: 1235px) {
  .bg::before,
  .bg::after {
    height: calc(47rem + 20vmin);
    width: calc(47rem + 20vmin);
  }

  .bg::before {
    transform: translate(-65%, 50%);
  }

  .bg::after {
    transform: translate(65%, -50%);
  }
}

@media (max-width: 1100px) {
  .bg::before,
  .bg::after {
    height: calc(47rem + 20vmin);
    width: calc(47rem + 20vmin);
  }

  .bg::before {
    transform: translate(-70%, 50%);
  }

  .bg::after {
    transform: translate(70%, -50%);
  }
}

@media (max-width: 650px) {
  .bg::before,
  .bg::after {
    height: calc(47rem + 20vmin);
    width: calc(47rem + 20vmin);
  }

  .bg::before {
    transform: translate(-75%, 50%);
  }

  .bg::after {
    transform: translate(75%, -50%);
  }
}

@media (max-width: 500px) {
  .bg::before,
  .bg::after {
    height: calc(47rem + 20vmin);
    width: calc(47rem + 20vmin);
  }

  .bg::before {
    transform: translate(-80%, 50%);
  }

  .bg::after {
    transform: translate(80%, -50%);
  }
}

</style>