<template>
  <div class="contacts">
    <div class="social">
      <h3>Contact</h3>
      <a href="https://t.me/ryt_13" title="@ryt_13">
        <img src="@/assets/icons/social/tg.svg" alt="t.me/ryt_13">
      </a>

      <a href="https://github.com/RyT-13" title="RyT-13">
        <img src="@/assets/icons/social/github.svg" alt="github.com/RyT-13">
      </a>

      <a href="https://discordapp.com/users/370303550317920257/" title="RyT#1735">
        <img src="@/assets/icons/social/discord.svg" alt="RyT#1735">
      </a>
    </div>

    <div class="mail">
      <a href="mailto: mail@voronko.dev">
        mail@voronko.dev
      </a>
      <span @click="copyToBuffer" @mouseleave="unhover" :class="{ copied: copied}" title="Copy">
              <img src="@/assets/icons/copy.svg" alt="Copy">
          </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactsBlock",

  data() {
    return {
      copied: false
    }
  },

  methods: {
    copyToBuffer() {
      navigator.clipboard.writeText('mail@voronko.dev')
          .then(() => {
            this.copied = true;
          })
          .catch(err => {
            console.log('Something went wrong', err);
          });
    },

    unhover() {
      if (this.copied) {
        setTimeout(() => {
          this.copied = false;
        }, 500);
      }
    }
  }
}
</script>

<style scoped>
.contacts {
  align-self: flex-end;

  display: flex;
  padding-top: 1.5rem;

  flex-direction: column;
  align-items: flex-end;
}

.social {
  display: flex;
  margin-bottom: 10px;
}

.social img {
  margin-left: 1rem;
  transition: box-shadow var(--duration);
}

.social img:hover {
  filter: brightness(0) saturate(100%) invert(68%) sepia(41%) saturate(3303%) hue-rotate(220deg) brightness(105%) contrast(105%);
  box-shadow: 0 0 1rem var(--second-color);
}

.social h3 {
  margin-right: 2rem;
}

.mail {
  white-space: nowrap;

  font-size: 2.5rem;
  font-weight: 300;
  line-height: 4rem;
}

.mail a {
  color: var(--primal-color);
}

.mail img {
  margin-left: 0.5rem;
  cursor: pointer;
}

.mail img:hover {
  filter: brightness(0) saturate(100%) invert(68%) sepia(41%) saturate(3303%) hue-rotate(220deg) brightness(105%) contrast(105%);
}

.mail .copied img {
  opacity: 0.4;
  cursor: initial;
}
</style>