<template>
  <component :is="tag">
    <slot/>
  </component>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'

export default {
  name: 'SmoothReflow',
  mixins: [smoothReflow],
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    options: Object,
  },
  mounted() {
    // this.$smoothReflow(this.options)
    this.$smoothReflow({
      property: ['height', 'width'],
      // transition: 'height .25s ease-in-out, width .75s ease-out'
      transitionEvent: {
        propertyName: 'opacity'
      }
    })
  }
}
</script>