<template>
  <div class="portfolio">
    <div class="portfolio-line">

      <PortfolioItem title="About Me" description='["Hello World!"];'>
        <div>Hi! I am Vasiliy Voronko, and I am developing various projects.
          <br>
          So if you need <span class="marker">website</span>, <span class="marker">web application</span> or
          <span class="marker">game prototype</span>,
          then write to me!
        </div>
        <br>
        <div>I am sure we'll work together :)</div>
      </PortfolioItem>

      <PortfolioItem title="Experience" description="More than 3 years">
        <div><span class="marker">2023-now</span>: MY.GAMES, Warface (<a href="https://warface.com/">warface.com</a>)  - <i>Backend</i>.
        </div>
        <div><span class="marker">2021-2023</span>: VK Company (<a href="https://vk.company">vk.company</a>)  - <i>Fullstack</i>.
        </div>
        <div><span class="marker">2020-2021</span>: Software Development Center (<a href="http://sdcv.ru/">sdcv.ru</a>) - <i>Fullstack</i>.
        </div>
        <br>
        <div><span class="marker">5 years</span>: Development on the Unity engine.</div>
        <div><span class="marker">2 years</span>: Retoucher handler in an antique store (Photoshop).</div>
      </PortfolioItem>

      <PortfolioItem title="Unity" description="More than 4 years">
        <div>Experience: <span class="marker">Since 2018</span></div>

        <div>VR Online Shop <span class="help">(prototype)</span></div>
        <div>VR Educational Platform <span class="help">(prototype)</span></div>
        <div>2D multiplayer shooter <span class="help">(client-side)</span></div>
        <div>2D runner-platformer <span class="help">(for game jam)</span></div>
        <br>
        <div>And several other small projects...</div>
      </PortfolioItem>
    </div>

    <div class="portfolio-line">
      <PortfolioItem title="< Main /> skills" description=".Net, Vue.js, Unity">
        <div><span class="marker">Back</span>: C# .Net Core (ASP.Net, Blazor, SharePoint).</div>
        <div><span class="marker">Front</span>: HTML, CSS (SCSS), Vue.js.</div>
        <div><span class="marker">Other</span>: Unity.</div>
      </PortfolioItem>

      <PortfolioItem title="< Other /> skills" description="Java, Python, SQL">
        <div><span class="marker">Back</span>: Java (Spring, Servlets), Python, Ruby (OnRails).</div>
        <div><span class="marker">Front</span>: TypeScript.</div>
        <div><span class="marker">Other</span>: SQL, Docker, Git, Figma, Photoshop.</div>
        <br>
        <div>And also many other things....</div>
      </PortfolioItem>

      <PortfolioItem title="My Works" description="Links">
        <span class="marker">Unity:</span>
        <div>Game Jam <span class="help">(1st place)</span> (<a
            href="https://mrmyp3uk.github.io/mg-gamejam-2022/">link</a>)
        </div>
        <br>
        <span class="marker">Front:</span>
        <div>Created Components with pure HTML & CSS (<a href="https://voronko.dev/components/">link</a>)</div>
        <div>Template Portfolio site (<a href="https://voronko.dev/mopso/">link</a>)</div>
        <div>Template Booking site (<a href="https://voronko.dev/villas/">link</a>)</div>
        <div>Template Gym site (<a href="https://voronko.dev/gym/">link</a>)</div>
      </PortfolioItem>
    </div>
  </div>
</template>

<script>
import PortfolioItem from "@/components/PortfolioItem";

export default {
  name: "ThePortfolio",
  components: {PortfolioItem}
}
</script>

<style scoped>
.portfolio {
  display: flex;
  margin-top: 10rem;

  flex-direction: column;
  justify-content: center;
}

.portfolio-line {
  display: flex;
  justify-content: center;
}


@media (max-width: 1640px) {
  .portfolio {
    flex-direction: row;
  }

  .portfolio-line {
    flex: 0 0 50%;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .portfolio-line:first-child {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 1100px) {
  .portfolio {
    margin-top: 5rem;
    flex-direction: column;
  }

  .portfolio-line,
  .portfolio-line:first-child {
    flex: 0 0 auto;

    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>