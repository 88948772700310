<template>
  <TheBackground></TheBackground>

  <TheProfile></TheProfile>
  <ThePortfolio></ThePortfolio>

  <div class="contacts-wrapper">
    <ContactsBlock></ContactsBlock>
  </div>
</template>

<script>
import TheProfile from "@/components/TheProfile";
import TheBackground from "@/components/TheBackground";
import ThePortfolio from "@/components/ThePortfolio";
import ContactsBlock from "@/components/ContactsBlock";

export default {
  name: 'App',
  components: {
    ContactsBlock,
    TheBackground,
    TheProfile,
    ThePortfolio
  }
}
</script>

<style>

/*noinspection CssUnknownTarget*/
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Indie+Flower&family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 13px;
}

:root {
  --primal-color: white;
  --second-color: #e292ff;
  --second-color-m: rgba(226, 145, 255, 0.5);
  --other-color: #262a2d;
  --font-color: var(--primal-color);
  --font-color-m40: rgba(255, 255, 255, 0.4);
  --font-color-m20: rgba(255, 255, 255, 0.2);
  --duration: 0.3s;
  --duration-fast: 0.15s;
}

body {
  /*font-family: "JetBrains Mono", monospace;*/
  font-family: 'Chakra Petch', sans-serif;
  color: var(--font-color);
  background-color: var(--other-color);
  scroll-direction: horizontal;
}


/**********     Override Tags       **********/

a {
  color: var(--second-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1,
h2 {
  font-weight: normal;
}

h1 {
  font-size: 3.5rem;
  line-height: 5rem;

}

h2 {
  font-size: 2.5rem;
  line-height: 4rem;
  font-weight: 500;
}

h3 {
  font-size: 2.5rem;
  line-height: 3rem;
}

/**********     Utils Design       **********/

.marker {
  color: var(--second-color);
  font-weight: 600;
}

.block-underline {
  padding-bottom: 0.5rem;
  border-bottom: 3px solid var(--primal-color);
}

.bold {
  font-weight: bold;
}

.help {
  color: var(--font-color-m20);
}

.interactive {
  cursor: pointer;
}

.contacts-wrapper {
  display: none;
  margin: 3rem 0 6rem;
}

.contacts-wrapper .contacts {
  align-items: center;
}


@media (min-width: 2565px) {
  :root {
    font-size: 15px;
  }
}

@media (max-width: 1640px) {

}

@media (max-width: 1560px) {

}

@media (max-width: 1235px) {

}

@media (max-width: 1100px) {

}

@media (max-width: 650px) {

}

@media (max-width: 500px) {
  h1 {
    font-size: 3rem;
    line-height: 5rem;

  }

  h2 {
    font-size: 2rem;
    line-height: 4rem;
    font-weight: 500;
  }

  h3 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .contacts-wrapper {
    display: block;
  }
}
</style>
